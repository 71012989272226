import { ref, computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import UserManagement from '@/modules/user-management/services/UserManagement';
import DataSources from '@/modules/data-wizard/services/DataSources';
import useToast from '@/composables/useToast';
import { userShortListUnit, IDsUser } from '../../services/interfaces';

interface IuseUserOptions {
    id: string;
    filter: {
        type: 0 | 1 | 2;
    };
}

export default function useIAMUsers(options?: Partial<IuseUserOptions>) {
    const iamUserList = ref<userShortListUnit[]>([]);
    const currUser = ref<IDsUser & { id: string }>();
    const { errorMessage } = useToast();
    const router = useRouter();
    const getIamUserList = (): Promise<void> =>
        UserManagement.getIAMUsers(options?.filter)
            .then((resp) => {
                iamUserList.value = resp?.data?.data || [];
            })
            .catch((error) => {
                console.error('Error: ', error);
            });

    function geIdpUserId(userId: string) {
        DataSources.getExistUser(userId as string)
            .then((resp) => {
                if (resp.data.data) {
                    currUser.value = resp.data.data;
                }
            })
            .catch((error) => {
                errorMessage(error, `User not found.`);
                router.push({ name: 'data-source-users-list' });
            });
    }

    const dsmUserName = computed(() => {
        if (!options?.id) return '';
        const user = iamUserList.value.find((el: userShortListUnit) => el?.id === currUser.value?.external_id);

        return user?.name;
    });

    if (!iamUserList.value.length) getIamUserList();

    if (options && 'id' in options && !options.id) {
        errorMessage('DSM User does not belong to current account', `User not found.`);
        router.push({ name: 'data-source-users-list' });
    }
    if (options?.id && !currUser.value) geIdpUserId(options.id);

    function getUserInfoById(userId: string): userShortListUnit | null {
        if (!iamUserList.value.length || !userId) return null;
        return iamUserList.value.find((el: userShortListUnit) => el.id === userId) ?? null;
    }

    function getUserName(userId: string): string {
        return getUserInfoById(userId)?.name || userId;
    }

    return {
        getIamUserList,
        geIdpUserId,
        iamUserList,
        dsmUserName,
        currUser,
        getUserName,
        getUserInfoById,
    };
}
