import DataSourcesService from '@/modules/data-wizard/services/DataSources';
import EventBus from '@/event';
import { DataSourceUserFilter, IDataSourceListItem } from './services/interfaces';
import { IAccessStatus } from '@/bff/common/bff-types/statuses';

export interface IDSStorage {
    color: string;
    name: string;
    value: number;
    grants: Record<
        string,
        {
            database: string[];
            global: string[];
            table: string[];
        }
    >;
}
export interface IDataSources {
    dataSources: IDataSourceListItem[];
    dataSourcesMeta: any;
    dataSourcesStatusList: any[];
    dataSourcesStorages: IDSStorage[];
    dataSourcesUserList: any[];
    dataSourcesUsersMeta: {
        current_page: number;
        from: number;
        last_page: number;
        per_page: number;
        to: number;
        total: number;
        filters: { [key: string]: DataSourceUserFilter };
    };
    dataSourcesUserStatusList: IAccessStatus[];
    dataSourcesLoading: boolean;
    dataSourcesStoragesLoading: boolean;
    dataSourcesStatusListLoading: boolean;
    dataSourcesUsersLoading: boolean;
    dataSourcesUserStatusListLoading: boolean;
}

const state = {
    dataSources: [],
    dataSourcesMeta: {},
    dataSourcesStatusList: [],
    dataSourcesStorages: [],
    dataSourcesUserList: [],
    dataSourcesUsersMeta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 15,
        to: 1,
        total: 1,
        filters: [],
    },
    dataSourcesUserStatusList: [],
    dataSourcesLoading: false,
    dataSourcesStoragesLoading: false,
    dataSourcesStatusListLoading: false,
    dataSourcesUsersLoading: false,
    dataSourcesUserStatusListLoading: false,
};

const getters = {
    dataSourcesIsLoading: (state: IDataSources) =>
        state.dataSourcesLoading ||
        state.dataSourcesStoragesLoading ||
        state.dataSourcesStatusListLoading ||
        state.dataSourcesUsersLoading ||
        state.dataSourcesUserStatusListLoading,
    getDataSourcesUsersFilter: (state: IDataSources) => state.dataSourcesUsersMeta,
    getDataSourcesStorages: (state: IDataSources) => state.dataSourcesStorages,
};

const actions = {
    updateDataSourcesList({ commit }: any, params: any = { limit: 1000 }) {
        commit('setLoading', { value: true, loader: 'dataSourcesLoading' });
        const { filters, sort, ...allParams } = params;

        return DataSourcesService.getDataSources(allParams, filters, sort)
            .then((resp: any) => {
                if (resp?.data?.data) {
                    commit('setDataSources', resp.data.data);
                }

                if (resp?.data?.meta) {
                    commit('setDataSourcesMeta', resp.data.meta);
                }
            })
            .then(() => {
                commit('setLoading', { value: false, loader: 'dataSourcesLoading' });
            })
            .catch((error: any) => {
                commit('setLoading', { value: false, loader: 'dataSourcesLoading' });
                EventBus.$emit('kmtx-toast-error', error, "Can't get data sources list.");
            });
    },

    getDataSourcesStorages({ commit }: any) {
        if (!state.dataSourcesStorages.length) {
            commit('setLoading', { value: true, loader: 'dataSourcesStoragesLoading' });
            return DataSourcesService.getDataSourcesStorages()
                .then((resp: any) => {
                    if (resp?.data?.data) {
                        commit('setDataSourcesStorages', resp.data.data);
                    }
                })
                .then(() => {
                    commit('setLoading', { value: false, loader: 'dataSourcesStoragesLoading' });
                })
                .catch((error: any) => {
                    commit('setLoading', { value: false, loader: 'dataSourcesStoragesLoading' });
                    EventBus.$emit('kmtx-toast-error', error, "Can't get data sources storages.");
                });
        }
    },

    getDataSourcesStatus({ commit }: any) {
        if (!state.dataSourcesStatusList.length) {
            commit('setLoading', { value: true, loader: 'dataSourcesStatusListLoading' });
            return DataSourcesService.getDataSourcesStatus()
                .then((resp: any) => {
                    if (resp?.data?.data) {
                        commit('setDataSourcesStatuses', resp.data.data);
                    }
                })
                .then(() => {
                    commit('setLoading', { value: false, loader: 'dataSourcesStatusListLoading' });
                })
                .catch((error: any) => {
                    commit('setLoading', { value: false, loader: 'dataSourcesStatusListLoading' });
                    EventBus.$emit('kmtx-toast-error', error, "Can't get data sources status list.");
                });
        }
    },

    getDataSourcesUsers({ commit }: any, params: string) {
        commit('setLoading', { value: true, loader: 'dataSourcesUsersLoading' });
        return DataSourcesService.getDataSourceUsersList(params)
            .then((resp: any) => {
                if (resp?.data) {
                    commit('setDataSourcesUsers', resp.data);
                }
                if (resp?.meta) {
                    commit('setDataSourcesUsersMeta', resp.meta);
                }
            })
            .then(() => {
                commit('setLoading', { value: false, loader: 'dataSourcesUsersLoading' });
            })
            .catch((error: any) => {
                commit('setLoading', { value: false, loader: 'dataSourcesUsersLoading' });
                EventBus.$emit('kmtx-toast-error', error, "Can't get data sources list.");
            });
    },

    getDataSourcesUserStatusList({ commit }: any) {
        if (!state.dataSourcesUserStatusList.length) {
            commit('setLoading', { value: true, loader: '.dataSourcesUserStatusListLoading' });

            return DataSourcesService.getDataSourceUserStatusName()
                .then((resp: IAccessStatus[]) => {
                    if (resp) {
                        commit('setDataSourcesUserStatuses', resp);
                    }
                })
                .then(() => {
                    commit('setLoading', { value: false, loader: '.dataSourcesUserStatusListLoading' });
                })
                .catch((error: any) => {
                    commit('setLoading', { value: false, loader: '.dataSourcesUserStatusListLoading' });
                    EventBus.$emit('kmtx-toast-error', error, "Can't get users status list.");
                });
        }
    },
};

const mutations = {
    setLoading(state: IDataSources, { value, loader }: any) {
        state[loader] = value;
    },

    setDataSources(state: IDataSources, payload: any) {
        state.dataSources.splice(0, state.dataSources.length, ...payload);
    },

    setDataSourcesMeta(state: IDataSources, payload: any) {
        state.dataSourcesMeta = payload;
    },

    setDataSourcesStatuses(state: IDataSources, payload: any) {
        state.dataSourcesStatusList = payload;
    },

    setDataSourcesStorages(state: IDataSources, payload: any) {
        state.dataSourcesStorages = payload;
    },
    setDataSourcesUsers(state: IDataSources, payload: any) {
        state.dataSourcesUserList = payload;
    },
    setDataSourcesUsersMeta(state: IDataSources, payload: any) {
        state.dataSourcesUsersMeta = payload;
    },

    setDataSourcesUserStatuses(state: IDataSources, payload: any) {
        state.dataSourcesUserStatusList = payload;
    },

    clearDataSources(state: IDataSources) {
        state.dataSources.splice(0, state.dataSources.length);
        state.dataSourcesStorages.splice(0, state.dataSourcesStorages.length);
        state.dataSourcesStatusList.splice(0, state.dataSourcesStatusList.length);
        state.dataSourcesUserStatusList.splice(0, state.dataSourcesUserStatusList.length);
        state.dataSourcesUserList.splice(0, state.dataSourcesUserList.length);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
