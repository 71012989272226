import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const SchemaMonitorList = () => import(/* webpackChunkName: "[ScemaMonitorList]" */ './pages/SchemaMonitorList.vue');
const SchemaMonitorCreate = () => import(/* webpackChunkName: "[ScemaMonitorCreate]" */ './pages/SchemaMonitorCreate.vue');
const SchemaMonitorEdit = () => import(/* webpackChunkName: "[ScemaMonitorEdit]" */ './pages/SchemaMonitorEdit.vue');
const shemaMonitorBrowserTitle = 'Bison Schema Monitor - Data Platform';

export default {
    path: '/bison/schema-monitor',
    component: RoutingViewContainer,
    name: 'schema-monitor',
    redirect: { name: 'schema-monitor-list' },
    children: [
        {
            path: '',
            component: SchemaMonitorList,
            name: 'schema-monitor-list',
            meta: {
                permission: 'schema-monitor:connections:connection',
                browserTitle: `Schema Monitor List - ${shemaMonitorBrowserTitle}`,
            },
        },
        {
            path: 'create',
            component: SchemaMonitorCreate,
            name: 'schema-monitor-create',
            meta: {
                permission: 'schema-monitor:connections:connection:create',
                browserTitle: `Create Schema Monitor Connection - ${shemaMonitorBrowserTitle}`,
            },
        },
        {
            path: ':connectionId',
            component: SchemaMonitorEdit,
            name: 'schema-monitor-edit',
            meta: {
                permission: 'schema-monitor:connections:connection',
                browserTitle: `Edit Schema Monitor Connection - ${shemaMonitorBrowserTitle}`,
            },
        },
    ],
};
