import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const FinTechContainer = () => import(/* webpackChunkName: "[FinTech]" */ '@/modules/dddm/pages/FinTechContainer.vue');
const UserProfiler = () => import(/* webpackChunkName: "[UserProfiler]" */ '@/modules/dddm/pages/UserProfiler.vue');
const dddmToolsBrowserTitle = 'DDDM Tools - Data Platform';

export default {
    path: '/dddm-tools',
    component: RoutingViewContainer,
    name: 'dddm-tools',
    redirect: { name: 'fintech-platform' },
    children: [
        {
            path: 'fintech-platform',
            component: FinTechContainer,
            name: 'fintech-platform',
            meta: {
                header: { tab: true },
                permission: ['dddm:solutions:list:view'],
                browserTitle: `FinTech Platform - ${dddmToolsBrowserTitle}`,
            },
        },
        {
            path: 'user-profiler',
            component: UserProfiler,
            name: 'user-profiler',
            meta: {
                permission: ['dddm:user-profiler:list:view'],
                browserTitle: `User profiler - ${dddmToolsBrowserTitle}`,
            },
        },
    ],
};
