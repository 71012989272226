import {
  ActionTree, Commit, GetterTree, Module, MutationTree,
} from 'vuex';
import Auth from '@/services/Auth';
import LocalStorage from '@/services/LocalStorage';
import { RootState } from '..';

export interface IPermission {
    id: string;
    name: string;
    slug: string;
}
export interface IRole {
    id: string;
    name: string;
    scope: number;
    permissions?: IPermission[];
    archived_at?: string | null;
}
export interface IAccount {
    id: string;
    name: string;
    roles: IRole[];
    incremental_id?: number;
    parent_id?: string | null;
    type?: 1 | 2;
    owner?: {
      id: string;
      name: string;
  };
    child_accounts?: {
        id: string;
        incremental_id: number;
        name: string;
        parent_id: string | null;
    }[];
}
export interface IUser {
    accounts: IAccount[];
    type: number;

    archived_at: string | null;
    avatar: string | null;
    created_at: string;
    created_by: string;
    id: string;
    master_account_id: string;
    status: number;
    updated_at: string;
    updated_by: string | null;
    email: string;
    name: string;
    timezone: string;
}
export interface IAuthState {
    token: string | null;
    currentUser: IUser | null;
    currentAccountId: string | null;
    currentAccountIncrementalId: string | null;
    isRefreshing: boolean;
    refreshingCall: RefreshingCall | null;
}

export interface DataSourcesResponse {
    account_id: string;
    archived_at: null;
    client_ips: string[];
    created_at: string;
    creator_id: string;
    id: string;
    external_id: string;
    login: string;
    request_purpose: string;
    sensitive_data: boolean;
    type: number;
    updated_at: string;
    updater_id: null;
    data_sources: {
        archived_at: null;
        created_at: string;
        creator_id: string;
        host: string;
        id: string;
        name: string;
        port: number;
        profile: {
            id: string;
            name: string;
            readonly: boolean;
        };
        public_host: null;
        roles: {
            id: string;
            name: string;
        }[];
        availability: {
            is_available: boolean;
            checked_at: string;
            description: string;
        };
        sensitive_data: boolean;
        storage: number;
        updated_at: string;
        updater_id: string | null;
        user_status: number;
        user_status_description: string | null;
   }[]
}

export type RefreshingCall = Promise<boolean | {data: unknown}>;
type Slug = {[key: string]: boolean};

export interface IAuthGetters {
    accounts: IAccount[];
    currentAccount: IAccount;
    currentAccountHighestRole: IRole;
    currentUser: IUser;
    hasAnyPermissionToService: boolean;
    masterAccountId: string;
    masterRole: IRole;
    permissions: IPermission[];
    permissionsSlugs: Slug;
}

/**
 * Role scopes definition
 * 1 - Master Owner || Service Owner
 * 2 - Master Admin || Service Admin
 * 3 - Owner
 * 4 - Admin
 * 5 - Manager
 * 6 - View-only
 * 7 - Custom role
 */
const state = {
  token: LocalStorage.get('token'),
  currentUser: null,
  currentAccountId: null,
  currentAccountIncrementalId: null,
  isRefreshing: false,
  refreshingCall: null,
};

const getters: GetterTree<IAuthState, RootState> = {
  currentUser: (state: IAuthState) => state.currentUser,
  currentUserId: (state: IAuthState) => state.currentUser?.id,
  currentAccount: (state: IAuthState, getters: IAuthGetters) => {
    const empty = {
      name: '',
      id: 0,
      roles: [],
    };
    if (!state.currentAccountId) {
      return empty;
    }
    const contextAcc = sessionStorage.getItem('context_account_id');
    const contextAccountExists = contextAcc
                              && getters.accounts?.find((account: IAccount) => account.id === contextAcc);
    const usedAccount = contextAccountExists ? contextAcc : state.currentAccountId;

    const acc = getters.accounts?.find((account: IAccount) => account.id === usedAccount);
    return acc ?? empty;
  },

  masterRole: (state: IAuthState, getters: IAuthGetters) => {
    const roles = [...getters.accounts.filter((acc) => acc.id === getters.masterAccountId).flatMap((account) => account.roles)];
    const rolesSortedByScope = roles.sort((a, b) => a.scope - b.scope);
    if (rolesSortedByScope?.length && rolesSortedByScope[0].scope <= 2) {
      return rolesSortedByScope[0];
    }
    return false;
  },

  currentAccountHighestRole: (state: IAuthState, getters: IAuthGetters) => {
    if (getters.masterRole) {
      return getters.masterRole;
    }
    const roles = [...getters.currentAccount.roles];
    const rolesSortedByScope = roles.sort((a, b) => a.scope - b.scope);
    return rolesSortedByScope && rolesSortedByScope[0];
  },

  masterAccountId: (state: IAuthState, getters: IAuthGetters) => getters.currentAccount.parent_id || getters.currentAccount.id,

  accounts: (state: IAuthState) => {
    if (state.currentUser?.accounts) {
      const child_accounts = state.currentUser.accounts.flatMap((account) => account.child_accounts);
      return [...state.currentUser.accounts, ...child_accounts].filter((v, i, a) => a.findIndex((t) => (t?.id === v?.id)) === i);
    }
    return [];
  },

  permissions: (state: IAuthState, getters: IAuthGetters) => {
    const customRolesPermissions = getters.currentAccount.roles?.filter((role) => role.scope === 100).flatMap((role) => role.permissions) || [];
    if (getters.masterRole) {
      const permissions = getters.masterRole.permissions ? getters.masterRole.permissions : [];
      return [...permissions, ...customRolesPermissions];
    }
    return [...getters.currentAccountHighestRole?.permissions || [], ...customRolesPermissions] || [];
  },

  permissionsSlugs: (state: IAuthState, getters: IAuthGetters) => {
    if (getters.permissions.length) {
      const slugs: Slug = {};
      getters.permissions.forEach(({ slug }) => slugs[slug] = true);
      return slugs;
    }
    return [];
  },

  hasAnyPermissionToService: (state: IAuthState, getters: IAuthGetters) => (service: string, type: 'view' | 'create' | 'edit' | 'delete' = 'view') => {
    const [, , , permission] = service.split(':');
    // TODO: refactor
    if (getters.permissions.length) {
      if (permission !== undefined) {
        return getters.permissionsSlugs[`${service}`] === true;
      }

      if (!service || ['view', 'edit', 'create', 'delete'].indexOf(type) == -1) {
        return false;
      }

      const checkedActions = {
        view: ['view', 'edit', 'create', 'delete'],
        create: ['create', 'edit', 'delete'],
        edit: ['edit', 'delete'],
        delete: ['delete'],
      };

      // @ts-ignore
      const permissionPoints = checkedActions[type].reduce((acc, item) => acc + (getters.permissionsSlugs[`${service}:${item}`] === true), 0);
      return permissionPoints > 0;
    }

    return false;
  },

  userTimeZone: (state: IAuthState): string => state.currentUser?.timezone ?? 'UTC',
};

const actions: ActionTree<IAuthState, RootState> = {
  getTokens({ commit }, params: { email: string; password: string; }) {
    return Auth.login(params).then((response) => {
      commit('storeTokens', response.data);
      return response;
    });
  },
  getTokensWithOAuth({ commit }, { driver, code, redirect_uri }: { driver: string; code: string; redirect_uri: string }) {
    return Auth.oAuthLogin(driver, code, redirect_uri).then((response) => {
      commit('storeTokens', response.data);
      return response;
    });
  },
  refreshTokens({ commit }) {
    const refreshToken = LocalStorage.get('refresh_token');
    if (refreshToken) {
      return Auth.refreshTokens(refreshToken).then((response) => {
        commit('storeTokens', response.data);
        return response;
      });
    }
    return Promise.reject('No refresh tokens found.');
  },
  getUser({ commit, getters }: {commit: Commit; getters: IAuthGetters}, id?: string, isLoaderShown = true) {
    return Auth.getCurrentUser({ showLoader: isLoaderShown }).then((response) => {
      const user = response.data.data;
      if (user) {
        let accountId: string | null | undefined = LocalStorage.get('account_id');
        const incrementalId = LocalStorage.get('account_incremental_id');

        commit('setCurrentUser', user);

        if (!getters.accounts.find(({ id }) => id === accountId)) {
          accountId = undefined;
        }
        const acc = accountId ? id ?? accountId : user.accounts[0].id;
        commit('setCurrentAccountId', acc);
        commit('setCurrentAccountIncrementalId', incrementalId || user.accounts[0].incremental_id);
        return response;
      }
      throw 'No user data received.';
    });
  },

  changeCurrentAccount(
    { commit, state },
    {
      accountId, incrementalId, contextAccountId,
    }: {accountId: string; incrementalId: string; contextAccountId: string},
  ) {
    if ((contextAccountId && contextAccountId !== state.currentAccountId) || state.currentAccountId !== accountId) {
      commit('setCurrentAccountId', accountId);
      commit('setCurrentAccountIncrementalId', incrementalId);
    }
  },
};

const mutations: MutationTree<IAuthState> = {
  storeTokens(state: IAuthState, payload: {token: string; refresh_token: string;}) {
    state.token = payload.token;
    localStorage.setItem('token', state.token);
    localStorage.setItem('refresh_token', payload.refresh_token);
  },
  destroyTokens(state: IAuthState) {
    state.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  },
  setRefreshingState(state: IAuthState, payload: boolean) {
    state.isRefreshing = payload;
  },
  setRefreshingCall(state: IAuthState, payload: RefreshingCall) {
    state.refreshingCall = payload;
  },
  setCurrentUser(state: IAuthState, payload: IUser) {
    state.currentUser = payload;
  },
  setCurrentAccountId(state: IAuthState, payload: string) {
    state.currentAccountId = payload;
    localStorage.setItem('account_id', payload);
  },
  setCurrentAccountIncrementalId(state: IAuthState, payload: string) {
    state.currentAccountIncrementalId = payload;
    localStorage.setItem('account_incremental_id', payload);
  },
};

const authModule: Module<IAuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
export default authModule;
