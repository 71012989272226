export function accessStatuses() {
    return [
        { name: "Created", value: 0 },
        { name: "Pending", value: 1 },
        { name: "Processing", value: 2 },
        { name: "Active", value: 3 },
        { name: "Archived", value: 4 },
        { name: "Failed", value: 5 },
        { name: "Deactivated", value: 6 }
    ];
}
