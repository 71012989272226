import { ApiV2 } from '@/api';
import convertEmptyStringToNull from '@/shared/utils/convertEmptyStringToNull';

export default {
    getServicesLists(params = { limit: 1000 }, options: any = {}) {
        return ApiV2(options)
            .get('sm/services/', { params })
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getServices(params = { limit: 1000 }, options: any = {}) {
        return ApiV2(options)
            .get('services/', { params })
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getServiceById(service_id = '') {
        return ApiV2({ showLoader: true })
            .get(`services/${service_id}`)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createNewService(payload: any) {
        return ApiV2({ showLoader: true })
            .post('sm/services/', convertEmptyStringToNull(payload))
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getService(serviceId: string) {
        return ApiV2({ showLoader: true })
            .get(`sm/services/${serviceId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateService(serviceId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .patch(`sm/services/${serviceId}`, convertEmptyStringToNull(payload))
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    archiveService(serviceId: string) {
        return ApiV2({ showLoader: true })
            .delete(`sm/services/${serviceId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    activateService(serviceId: string) {
        return ApiV2({ showLoader: true })
            .patch(`sm/services/${serviceId}/activate`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    restoreService(serviceId: string) {
        return ApiV2({ showLoader: true })
            .patch(`sm/services/${serviceId}/restore`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createPermission(serviceId: string, payload: any) {
        return ApiV2()
            .post(`sm/services/${serviceId}/permission`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createOneActionPermission(serviceId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .post(`sm/services/${serviceId}/permissions`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updatePermission(serviceId: string, permissionId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .put(`sm/services/${serviceId}/permission/${permissionId}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateOneActionPermission(serviceId: string, permissionId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .patch(`sm/services/${serviceId}/permissions/${permissionId}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    removePermission(serviceId: string, permissionId: string) {
        return ApiV2({ showLoader: true }).delete(`sm/services/${serviceId}/permission/${permissionId}`);
    },

    createPermissionGroups(serviceId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .post(`sm/services/${serviceId}/permission-groups`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updatePermissionGroups(serviceId: string, slug: string, payload: any) {
        return ApiV2({ showLoader: true })
            .patch(`sm/services/${serviceId}/permission-groups/${slug}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    removePermissionGroups(serviceId: string, slug: string) {
        return ApiV2({ showLoader: true }).delete(`sm/services/${serviceId}/permission-groups/${slug}`);
    },

    getAPIKeys(serviceId: string, search?: string) {
        const params = { headers: {} };
        if (search) {
            params.headers = { 'X-Key-Token': search };
        }
        return ApiV2({ showLoader: true }, params)
            .get(`sm/services/${serviceId}/api-keys`)
            .then((resp: any) => resp?.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createAPIKey(serviceId: string, payload: any) {
        return ApiV2({ showLoader: true })
            .post(`sm/services/${serviceId}/api-keys`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    deleteAPIKey(serviceId: string, keyId: string) {
        return ApiV2({ showLoader: true }).delete(`sm/services/${serviceId}/api-keys/${keyId}`);
    },
};
