
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '';

export default {
    /**
     * Simple page layout wrapper for the updated design
     */
    name: 'PageLayout',

    props: {
        size: {
            type: String,
            default: '',
            validator: (prop: Size) => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', ''].includes(prop),
        },
    },
};
