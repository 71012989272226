import RoutingViewContainer from '@/components/RoutingViewContainer.vue';
import TrackingFormsRoute from './modules/forms/forms.route';

function setQueryParamsForCatalog(to, from, next) {
    const defaultQueryParams = {
        search_value: '',
        search_by: 'event_name',
        aggregate_by: 0,
        per_page: 100,
        page: 1,
        skins: '1',
        routes: '',
        rules: '',
        types: '',
        min_date: '',
        max_date: '',
    };

    const queryParamsKeys = Object.keys(to.query);

    if (Object.keys(defaultQueryParams).every((key) => queryParamsKeys.includes(key))) {
        next();
    } else if (queryParamsKeys.length) {
        next({
            ...to,
            query: {
                ...defaultQueryParams,
                ...to.query,
            },
        });
    } else {
        next({
            ...to,
            query: { ...defaultQueryParams },
        });
    }
}

export default {
    path: '/tracking',
    name: 'tracking',
    component: RoutingViewContainer,
    children: [
        {
            path: 'catalog',
            name: 'tracking-catalog',
            component: () => import(/* webpackChunkName: '[TrackingCatalogPage]' */ './pages/catalog/CatalogPage.vue'),
            beforeEnter: setQueryParamsForCatalog,
            meta: {
                permission: 'tracking:rules:rule:view',
                browserTitle: 'Catalog - Tracking',
            },
        },
        {
            path: 'rules',
            name: 'tracking-rules',
            component: () => import(/* webpackChunkName: '[TrackingRulesPage]' */ './pages/RulesPage.vue'),
            meta: {
                permission: 'tracking:rules:rule',
                browserTitle: 'Rules - Tracking',
            },
        },

        TrackingFormsRoute,
    ],
};
