import { ApiV1 } from '@/api';

export default {
    createSources(data: any, options: any = {}) {
        options.cantCancel = true;

        return ApiV1(options).post('/sources', data);
    },

    updateSources(id: any, params: any) {
        return ApiV1({ cantCancel: true }).put(`/sources/${id}`, params);
    },

    deleteSource(id: number) {
        return ApiV1().delete(`/sources/${id}`);
    },

    getSources(params: any = {}) {
        params.perPage = this.itemsPerPage(params.perPage);

        return ApiV1().get('/sources', { params });
    },

    getSourceLabels(params: any = {}) {
        params.perPage = this.itemsPerPage(params.perPage);

        return ApiV1().get('/sources?expand=labels', { params });
    },

    // TODO: KMTX-11999
    getLabels(params: any = {}, options?: any) {
        params.perPage = this.itemsPerPage(params.perPage);

        return ApiV1(options).get('/labels', { params });
    },

    // TODO: KMTX-12029
    getRoutes(params: any = {}) {
        params.perPage = this.itemsPerPage(params.perPage);

        return ApiV1().get('/routes', { params });
    },

    // TODO: Looks like it also scope of KMTX-11999
    getSkins() {
        return ApiV1().get('/dictionary_list/2');
    },

    // TODO: Move to DataSourceService.ts in tracking-data-source
    createIntegrationMode(params: any) {
        return ApiV1({ cantCancel: true }).post('/integration-mode', params);
    },

    itemsPerPage(perPage = 100000) {
        return perPage;
    },
};
