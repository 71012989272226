import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

export default {
    path: 'forms',
    name: 'tracking-forms',
    component: RoutingViewContainer,
    redirect: { name: 'tracking-forms-list' },
    children: [
        {
            path: '',
            name: 'tracking-forms-list',
            component: () => import(/* webpackChunkName: '[TrackingFormsPage]' */ './pages/FormsPage.vue'),
            meta: {
                permission: 'tracking:rules:rule:view',
                browserTitle: 'Forms - Tracking',
            },
        },
        {
            path: ':id',
            name: 'tracking-form',
            component: () => import(/* webpackChunkName: '[TrackingFormPage]' */ './pages/FormPage.vue'),
            meta: {
                permission: 'tracking:rules:rule:view',
                browserTitle: 'Form information - Forms - Tracking',
            },
        },
    ],
};
