import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const KafkaBrokers = () => import(/* webpackChunkName: "[KafkaBrokers]" */ './pages/KafkaBrokers.vue');
const KafkaConnectClusters = () => import(/* webpackChunkName: "[KafkaConnectClusters]" */ './pages/KafkaConnectClusters.vue');
const Integrations = () => import(/* webpackChunkName: "[Integrations]" */ './pages/Integrations.vue');
const SnowflakeSnapshot = () => import(/* webpackChunkName: "[SnowflakeSnapshot]" */ './pages/SnowflakeSnapshot.vue');
const browserTitle = 'Bison Stream Management - Data Platform';

export default {
    path: '/bison/stream-management',
    component: RoutingViewContainer,
    name: 'schema-monitor',
    redirect: { name: 'kafka-connect-clusters' },
    children: [
        {
            path: 'kafka-brokers',
            component: KafkaBrokers,
            name: 'kafka-brokers',
            meta: {
                permission: 'bsm:kafka:brokers:view',
                browserTitle: `Kafka Brokers - ${browserTitle}`,
            },
        },
        {
            path: 'kafka-connect-clusters',
            component: KafkaConnectClusters,
            name: 'kafka-connect-clusters',
            meta: {
                permission: 'bsm:kafka:connect-clusters:view',
                browserTitle: `Kafka Connect Clusters - ${browserTitle}`,
            },
        },
        {
            path: 'integrations',
            component: Integrations,
            name: 'bsm-integrations',
            meta: {
                permission: 'bsm:kafka:integrations:view',
                browserTitle: `Integrations - ${browserTitle}`,
            },
        },
        {
            path: 'snowflake-snapshot',
            component: SnowflakeSnapshot,
            name: 'snowflake-snapshot',
            meta: {
                permission: 'bsm:snowflake:snapshot:view',
                browserTitle: `Snowflake Snapshot - ${browserTitle}`,
            },
        },
    ],
};
